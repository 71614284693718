import { Modal } from 'react-bootstrap';
import React from 'react';
import styles from './ExpediteModal.module.css';

const ExpediteModal = (props) => {
    const { showExpediteModal, toggleExpediteModal, expedites, sampleCount } = props;
    const getTotalCount = (data) => sampleCount.reduce((acc, curr) => acc + curr.count, 0);
    return (
        <Modal
            show={showExpediteModal}
            onHide={toggleExpediteModal}
            size="xl"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-center"><h2 className={styles.heading}>Expedites</h2></Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ whiteSpace: "pre-wrap" }}>
                <div className="row">
                    <div className="col-md-7 sample-table">
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th className={styles.header} colSpan="2" style={{ width: "80%" }}>Sample Count</th>
                                    <th className={styles.header} style={{ width: "20%" }}>Expedite</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sampleCount?.length ? sampleCount.map((item, index) => {
                                    return (
                                        <tr className={styles.tableSampleRow} key={`${item.name}-${index}`}>
                                            <td style={{ width: "60%" }}>{item.name}</td>
                                            <td className='text-center'>{item.count}</td>
                                            <td> <input
                                                className={styles.select_checkbox}
                                                type="checkbox"
                                                value={item.expedite}
                                            /></td>
                                        </tr>
                                    )
                                }) : <tr><td colSpan="3" className='text-center'>No Sample Count</td></tr>}
                                {sampleCount?.length ? <tr className={styles.totalRow}>
                                    <td style={{ width: "60%" }}>Total</td>
                                    <td className={styles.totalCount}>{getTotalCount()}</td>
                                    <td></td>
                                </tr> : null}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-5">
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th className={styles.headerExpedites} colSpan={2}>Expedites</th>
                                </tr>
                            </thead>
                            <tbody>
                                {expedites.map((item, index) => {
                                    return (
                                        <tr key={`${item.key}-${index}`}>
                                            <td style={{ width: "70%" }} >{item.label}</td>
                                            <td className='font-weight-bold' >{`$${item.cost}`}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className={styles.doneButton} onClick={toggleExpediteModal}>Close</button>
            </Modal.Footer>
        </Modal>

    );
}

export default ExpediteModal;