import React, { useState } from "react";

import { Button } from "react-bootstrap";
import ExpediteModal from "./ExpediteModal/ExpediteModal";
import FinancialModal from "./FinancialModal/FinancialModal";
import styles from "./addOn.module.css";

const AddOns = (props) => {
  const { expedites = [], sampleCount = [] } = props;
  const [showExpediteModal, setShowExpediteModal] = useState(false);
  const [showFinancialModal, setShowFinancialModal] = useState(false);
  const toggleExpediteModal = () => {
    setShowExpediteModal(!showExpediteModal);
  };
  const toggleFinancialModal = () => {
    setShowFinancialModal(!showFinancialModal);
  };
  return (
    <>
      <div className={styles.container}>
        <Button
          className={styles.btnExpedite}
          size="sm"
          onClick={toggleExpediteModal}
        >
          Expedite
        </Button>
        <Button
          className={styles.btnFinancial}
          size="sm"
          onClick={toggleFinancialModal}
        >
          Financial Info
        </Button>
      </div>
      <ExpediteModal expedites={expedites} sampleCount={sampleCount} showExpediteModal={showExpediteModal} toggleExpediteModal={toggleExpediteModal} />
      <FinancialModal showFinancialModal={showFinancialModal} toggleFinancialModal={toggleFinancialModal} />
    </>
  );
};

export default AddOns;
