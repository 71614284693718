import React, { useState } from "react";
import { costData, financedData, financingProgram, sampleData } from "../../utility/constant";

import { Modal } from "react-bootstrap";
import styles from "./FinancialModal.module.css";

const FinancialModal = (props) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [discountData, setDiscountData] = useState([
    { name: "Other Discount $", count: 0.0 },
    { name: "Sample Discount $", count: 50.0 },
  ]);

  const { showFinancialModal, toggleFinancialModal } = props;

  const handleSelectChange = (event) => setSelectedOption(event.target.value);

  const handleInputChange = (index, value) => {
    const updatedData = [...discountData];
    updatedData[index].count = value;
    setDiscountData(updatedData);
  };
  const formatCurrency = (value) => `$${value.toFixed(2)}`;

  const getTotalCount = () => {
    const total = financedData.reduce((acc, item) => acc + item.cost, 0);
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total);
  };

  return (
    <Modal
      show={showFinancialModal}
      onHide={toggleFinancialModal}
      size="xl"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center">
          <h2 className={styles.heading}>Financing</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ whiteSpace: "pre-wrap" }}>
        <div className="financial-container row">
          {/* Financing Table */}
          <div className="col-md-6 sample-table">
            <table className={styles.financingTable}>
              <thead>
                <tr>
                  <th className={styles.header} colSpan="2">
                    Financing Program
                  </th>
                </tr>
              </thead>
              <tbody>
                {financingProgram.map((item, index) => (
                  <tr key={`${item.name}-${index}`}>
                    <td style={{ fontWeight: "bold" }}>{item.name}</td>
                    <td style={{ width: "30%" }}>
                      {index === 0 ? (
                        <select
                          style={{ width: "100%", padding: "5px", border: "1px solid #ccc" }}
                          value={selectedOption}
                          onChange={handleSelectChange}
                        >
                          <option value="">12 Months</option>
                          <option value="Option 1">10 Months</option>
                          <option value="Option 2">8 Months</option>
                          <option value="Option 3">6 Months</option>
                        </select>
                      ) : (
                        <span style={{ fontWeight: item.name === "Monthly Payment" ? "bold" : "normal" }}>
                          {item.name === "Monthly Payment" ? `$${item.count.toFixed(2)}` : ""}
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Financed Data Table */}
          <div className="col-md-6">
            <table className={styles.table}>
              <tbody>
                {financedData.map((item, index) => (
                  <tr key={`${item.name}-${index}`}>
                    <td>{item.name}</td>
                    <td style={{ width: "30%" }} className="font-weight-bold">
                      {`$${item.cost}`}
                    </td>
                  </tr>
                ))}
                <tr className={styles.totalRow}>
                  <td>Total Financed</td>
                  <td className={styles.totalCount}>{getTotalCount()}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Discount Table */}
          <h2
            className={styles.heading}
            style={{
              marginTop: "50px",
              paddingLeft: "520px",
              marginBottom: "20px",
            }}
          >
            Discount
          </h2>
          <div className="col-md-12">
            <table className={styles.tableDiscount}>
              <tbody>
                {discountData.map((item, index) => (
                  <tr key={`${item.name}-${index}`}>
                    <td>{item.name}</td>
                    <td style={{ width: "13%" }} className="font-weight-bold">
                      {index === 0 ? (
                        <input
                          style={{
                            width: "100px",
                            backgroundColor: "blanchedalmond",
                            borderRadius: "5px", border: "1px solid #ccc",
                            paddingLeft: "25px"
                          }}
                          type="text"
                          value={formatCurrency(item.count)}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              parseFloat(e.target.value.replace(/[^0-9.]/g, ""))
                            )
                          }
                        />
                      ) : (
                        `$${item.count.toFixed(2)}`
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <h2
            className={styles.heading}
            style={{
              marginTop: "40px",
              paddingLeft: "500px",
              marginBottom: "20px",
            }}
          >
            Sample Costs
          </h2>
          <div className="col-md-6 ">
            <table className={styles.sampleTable}>
              <tbody>
                {sampleData.map((item, index) => (
                  <tr key={`${item.name}-${index}`}>
                    <td>{item.name}</td>
                    <td style={{ width: "20%" }}>
                      {formatCurrency(item.count)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Financed Data Table */}
          <div className="col-md-6">
            <table className={styles.sampleTable}>
              <tbody>
                {costData.map((item, index) => (
                  <tr key={`${item.name}-${index}`}>
                    <td>{item.name}</td>
                    <td style={{ width: "20%" }} className="font-weight-semibold">
                      {formatCurrency(item.count)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button className={styles.doneButton} onClick={toggleFinancialModal}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default FinancialModal;