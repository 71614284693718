import { discountList } from "./constant";

export const calculateTotalSampleCost = (data = []) => {
  let totalSampleCost = 0;
  data.forEach((row) => {
    if (!row.client_approved && row.status && row.discount.name === discountList[0].name) {
      totalSampleCost += Number(row.test_sample.cost);
    }
  });
  return totalSampleCost;
};

export const calculateTotalDiscountOnTable = (data = []) => {
  let totalDiscountCost = 0;
  data.forEach((row) => {
    if (!row.client_approved && row.status && row.discount.name === discountList[1].name) {
      totalDiscountCost += Number(row.test_sample.cost);
    }
  });
  return totalDiscountCost;
};
