export const emails = [
  { email: "bu@example.com" }, // Dev
  { email: "charles_co@mailinator.com" }, // QA
  { email: "reports@yesweinspect.com" }, // Prod
];

export const isSampleStrategyReportShown = (user) => {
  const companyEmail = user?.data?.email || "";
  return emails.find((email) => email.email.trim() === companyEmail.trim());
};
