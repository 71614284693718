export const GET_TEST_LIST_REQUEST = "GET_TEST_LIST_REQUEST";
export const GET_TEST_LIST_SUCCESS = "GET_TEST_LIST_SUCCESS";
export const GET_TEST_LIST_FAIL = "GET_TEST_LIST_FAIL";
export const GET_TEST_LIST_RESET = "GET_TEST_LIST_RESET";


export const GET_INSPECTOR_LIST_REQUEST = "GET_INSPECTOR_LIST_REQUEST";
export const GET_INSPECTOR_LIST_SUCCESS = "GET_INSPECTOR_LIST_SUCCESS";
export const GET_INSPECTOR_LIST_FAIL = "GET_INSPECTOR_LIST_FAIL";
export const GET_INSPECTOR_LIST_RESET = "GET_INSPECTOR_LIST_RESET";


export const GET_REPORT_DATA_REQUEST = "GET_REPORT_DATA_REQUEST";
export const GET_REPORT_DATA_SUCCESS = "GET_REPORT_DATA_SUCCESS";
export const GET_REPORT_DATA_FAIL = "GET_REPORT_DATA_FAIL";
export const GET_REPORT_DATA_RESET = "GET_REPORT_DATA_RESET";


export const ROW_SELECTION_REQUEST = "ROW_SELECTION_REQUEST";
export const ROW_SELECTION_SUCCESS = "ROW_SELECTION_SUCCESS";
export const ROW_SELECTION_FAIL = "ROW_SELECTION_FAIL";


export const CLIENT_FLAG_SELECTION_REQUEST = "CLIENT_FLAG_SELECTION_REQUEST";
export const CLIENT_FLAG_SELECTION_SUCCESS = "CLIENT_FLAG_SELECTION_SUCCESS";
export const CLIENT_FLAG_SELECTION_FAIL = "CLIENT_FLAG_SELECTION_FAIL";


export const UPDATE_HEADER_INFO_REQUEST = "UPDATE_HEADER_INFO_REQUEST";
export const UPDATE_HEADER_INFO_SUCCESS = "UPDATE_HEADER_INFO_SUCCESS";
export const UPDATE_HEADER_INFO_FAIL = "UPDATE_HEADER_INFO_FAIL";


export const UPDATE_STRATEGY_REPORT_TABLE_LIST_REQUEST = "UPDATE_STRATEGY_REPORT_TABLE_LIST_REQUEST"
export const UPDATE_STRATEGY_REPORT_TABLE_LIST_SUCCESS = "UPDATE_STRATEGY_REPORT_TABLE_LIST_SUCCESS"
export const UPDATE_STRATEGY_REPORT_TABLE_LIST_FAIL = "UPDATE_STRATEGY_REPORT_TABLE_LIST_FAIL"

export const SAVE_REPORT_DATA_REQUEST = "SAVE_REPORT_DATA_REQUEST";
export const SAVE_REPORT_DATA_SUCCESS = "SAVE_REPORT_DATA_SUCCESS";
export const SAVE_REPORT_DATA_FAIL = "SAVE_REPORT_DATA_FAIL";

export const REFRESH_REPORT_DATA_REQUEST = "REFRESH_REPORT_DATA_REQUEST";
export const REFRESH_REPORT_DATA_SUCCESS = "REFRESH_REPORT_DATA_SUCCESS";
export const REFRESH_REPORT_DATA_FAIL = "REFRESH_REPORT_DATA_FAIL";

export const PUBLISH_REPORT_DATA_REQUEST = "PUBLISH_REPORT_DATA_REQUEST";
export const PUBLISH_REPORT_DATA_SUCCESS = "PUBLISH_REPORT_DATA_SUCCESS";
export const PUBLISH_REPORT_DATA_FAIL = "PUBLISH_REPORT_DATA_FAIL";


export const GET_SAMPLE_STRATEGY_REPORT_RESET = "GET_SAMPLE_STRATEGY_REPORT_RESET";
