import React from "react";
import styles from "./report_header_info.module.css";
import SelectDropDown from "../Components/SelectDropdown/SelectDropDown";
import { useDispatch, useSelector } from "react-redux";
import { color, convertLabelIntoValueAndOption } from "main/shared/helpers";
import { updateHeaderInfoAction } from "store/actions/sample_strategy/sampleStrategyAction";

const ReportHeaderInfo = () => {
  const dispatch = useDispatch();
  const sampleStrategyReport = useSelector(
    (state) => state.sampleStrategyReport
  );
  const { inspectorList, reportData = {} } = sampleStrategyReport;

  const customer = reportData?.report_header?.customer || {};
  const field_inspector = reportData?.report_header?.field_inspector || [];
  const lead = reportData?.report_header?.lead || {};
  // const business_unit = reportData?.report_header?.business_unit || {};
  // console.log({ headerInfo: reportData?.report_header });
  const inspectorListOptions =
    convertLabelIntoValueAndOption(
      inspectorList,
      "inspectorId",
      "inspectorName"
    ) || [];

  const handleInspectorChange = (event) => {
    const selectedInspectors = event?.length
      ? event.map((inspector, index) => ({
          id: Number(inspector.value),
          name: inspector.label,
        }))
      : [];

    dispatch(
      updateHeaderInfoAction({
        key: "field_inspector",
        value: selectedInspectors,
      })
    );
  };
  const handleLeadChange = (event) => {
    dispatch(
      updateHeaderInfoAction({
        key: "lead",
        value: { id: Number(event.value), name: event.label },
      })
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.information_container}>
        <div>
          <div className={styles.info_section}>
            <div className={styles.info_key}>Client</div>
            <div className={styles.client_info}>
              {customer?.first_name} {customer?.lastname}
            </div>
          </div>

          <div className={styles.info_section}>
            <div className={styles.info_key}>Lead</div>
            <div className={styles.lead_info}>
              <SelectDropDown
                placeholder="Select Lead .."
                options={inspectorListOptions}
                handleOnChange={(e) => handleLeadChange(e)}
                searchValue={{ value: Number(lead.id), label: lead.name }}
                isSearchable={false}
                isClearable={false}
              />
            </div>
          </div>

          <div className={styles.info_section}>
            <div className={styles.info_key}>Field Inspector</div>
            <div className={styles.inspector_info}>
              <SelectDropDown
                closeMenuOnSelect={false}
                placeholder="Select Inspector .."
                options={inspectorListOptions}
                handleOnChange={(e) => handleInspectorChange(e)}
                searchValue={field_inspector.map((inspector, index) => ({
                  value: Number(inspector.id),
                  label: inspector.name,
                  color: index % 2 === 0 ? "#eaf6ff" : "#d4ffdb",
                }))}
                isSearchable={false}
                isClearable={false}
                isMulti={true}
                showMultiRemoveValue={true}
              />
            </div>
          </div>
        </div>

        <div>
          <img
            src={require("assets/logo/logo_sidenav.png").default}
            alt="company_logo"
          />
        </div>
      </div>
    </div>
  );
};

export default ReportHeaderInfo;
