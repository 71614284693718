import { Card, ListGroup } from "react-bootstrap";
import { useMemo, useState } from "react";

import arrowDownIcon from "../../../../assets/icons/arrow-down.png";
import arrowUpIcon from "../../../../assets/icons/arrow-up.png";
import styles from "./summary.module.css";

const Summary = (props) => {
  const { summary = {} } = props;
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => setShowDetails((prev) => !prev);

  const arrowIcon = useMemo(() => showDetails ? arrowUpIcon : arrowDownIcon, [showDetails]);
  const summaryData = useMemo(() => {
    return [
      { label: "Inspection Fee (paid)", value: `$${summary?.inspection_fee}`, editable: true },
      { label: "Service Fee (additional)", value: `$${summary?.service_fee}`, editable: true },
      { label: "Total Sample Cost", value: `$${summary?.total_sample_cost}` },
      { label: "Shipping", value: `$${summary?.shipping}` },
      { label: "Expedite Fees", value: `$${summary?.expediate_fees}` },
      { label: "Total Discount", value: `$${summary?.total_discont}` },
      { label: "Total Cost", value: `$${summary?.total_cost}`, isTotal: true },
    ]
  }, [summary])
  return (
    <div className={styles.Summary}>
      <button
        className={styles.header}
        onClick={toggleDetails}
        style={{ cursor: "pointer" }}
      >
        <div className={styles.icon}>
          <img src={arrowIcon} alt="Toggle Details" />
          <span>Sampling Balance</span>
        </div>
        <span className={styles.balance}>${summary?.sampling_balance}</span>
      </button>

      {showDetails && (
        <Card className={styles.container}>
          <ListGroup variant="flush">
            {summaryData.map((item, index) => (
              <ListGroup.Item
                key={index}
                className={item.isTotal ? styles.total : styles.listItem}
              >
                <span>{item.label}</span>
                {item.editable ? (
                  <input
                    className={styles.editorInput}
                    type="text"
                    value={item.value}
                    readOnly
                  />
                ) : (
                  <span className={styles.price}>{item.value}</span>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card>
      )}
    </div>
  );
};

export default Summary;